/*eslint-disable*/
import React ,{ useState } from "react";
import { navigate,Link } from 'gatsby'
import { useUser } from '../context/UserContext'
import { useDialog } from "../context/DialogContext";
import { useForm } from "react-hook-form";
import { useLoading } from 'context/LoadingContext'
import { styled, useTheme } from "@mui/material/styles"
import InputAdornment from '@mui/material/InputAdornment';
import Face from "@mui/icons-material/Face";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import  Lock  from "@mui/icons-material/Lock";



import IconButton from '@mui/material/IconButton';

import LandingPage from '../LandingPageMaterial/Layout/LandingPage'
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js";
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js";
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js";
import Button from "components/material-kit-pro-react/components/CustomButtons/Button.js";
import Card from "components/material-kit-pro-react/components/Card/Card.js";
import CardBody from "components/material-kit-pro-react/components/Card/CardBody.js";
import CardHeader from "components/material-kit-pro-react/components/Card/CardHeader.js";
import CustomInput from "components/material-kit-pro-react/components/CustomInput/CustomInput.js";

import loginPageStyle from "components/material-kit-pro-react/views/loginPageStyle"
import { graphql, useStaticQuery } from "gatsby";




const NewDivCont = styled("div")(({ theme }) => {
  const newStyles = loginPageStyle(theme)
  return {
    ...newStyles.container,
  }
})

const NewDivCenter = styled("div")(({ theme }) => {
  const newStyles = loginPageStyle(theme)
  return {
    ...newStyles.textCenter,
  }
})

const NewH4 = styled("h4")(({ theme }) => {
  const newStyles = loginPageStyle(theme)
  return {
    ...newStyles.cardTitle,
  }
})

const NewForm = styled("form")(({ theme }) => {
  const newStyles = loginPageStyle(theme)
  return {
    ...newStyles.form,
  }
})

const NewLink = styled(Link)(({ theme }) => {
  const newStyles = loginPageStyle(theme)
  return {
    ...newStyles.links,
  }
})

/*const defaultValues = {
  p_portal_username:'',
  p_pwd:''
  
}; */

export default function LoginPage() {

  const data = useStaticQuery(
    graphql`
    {
      allStrapiAssetsMedias: allStrapiAssetsMedia ( filter: {codigo: {eq: "ACCESS_BACKGROUND"}}){
        edges{
          node{
            archivo{
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }`
  );

  const backgroundImage = data.allStrapiAssetsMedias.edges[0]?.node.archivo.localFile.childImageSharp.gatsbyImageData.images.fallback.src


  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  
  const theme = useTheme()
  const styles = loginPageStyle(theme)
  const dialog = useDialog();
  const loading = useLoading();

  // const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
  //const { usuario, login } = useUsuario() || { usuario: "publico" , login : "on"};
  const { login } = useUser() || { login : ""};

  const methods = useForm({ mode: 'onSubmit', defaultValues:{
    p_portal_username:'',
    p_pwd:''
    
  } });
    const {
        handleSubmit,
        control,
        register,
        formState: { isSubmitting, errors },
    } = methods;
  
  const [showPassword, setshowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  };

  async function onSubmit(data, e) {
    e.preventDefault();

    try {
      loading(true)
      const dataLogin = await login(data)
      loading(false)
      navigate(dataLogin.user["HOME"]);
    } catch (error) {
      console.error(error)
      loading(false)
      dialog({
        variant: "info",
        catchOnCancel: false,
        title: "Alerta",
        description: error.response.data
      })
    }
  };

  return (
    <LandingPage pageTitle="Inicio de Sesión">
      <Parallax
        image={backgroundImage ? backgroundImage : null}
        className={styles.parallax}
      >
        <NewDivCont>
          <GridContainer justify="center" className={styles.containerPaddingBottom}>
            <GridItem xs={12} sm={12} md={4}>
              <Card sx={{overflow:'visible',mt:6}}>
                <NewForm onSubmit={handleSubmit(onSubmit)} noValidate>
                  <CardHeader color="primary" signup className={styles.cardHeader}>
                    <NewH4>Inicia Sesión</NewH4>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Usuario",
                        type: "text",
                        name:"p_portal_username",
                        ...register('p_portal_username',  { required: true }),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Face className={styles.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      
                    />
                    <p className="error">{errors.p_portal_username && "Debe indicar el usuario"}</p>
                    <CustomInput
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Clave",
                        name  : "p_pwd",
                        ...register('p_pwd',  { required: true }),
                        type: showPassword ? 'text' : 'password',
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                        endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <p className="error">{errors.p_pwd && "Debe indicar la clave"}</p>
                  </CardBody>
                  <NewDivCenter>
                    <Button color="primary" type="submit">
                      Entrar
                    </Button>
                    <br></br>
                    <small>
                    <NewLink to={`/reiniciar_clave`} >
                      Recupera tu clave
                    </NewLink>
                    </small>
                    <br></br>
                    <small>
                    <NewLink to={`/recuperar_usuario`} >
                      ¿Olvidaste tu usuario?
                    </NewLink>
                    </small>
                    <br></br>
                    <br></br>
                  </NewDivCenter>
                </NewForm>
              </Card>
            </GridItem>
          </GridContainer>
        </NewDivCont>
        </Parallax>
    </LandingPage>
  );
}
